<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>Ürünler</CCardHeader>
                <CCardBody>
                    <CDataTable
                        hover
                        striped
                        :items="products"
                        :fields="fields"
                        clickable-rows
                        @row-clicked="redirectProduct"
                    >
                        <template #price="data">
                            <td>
                                {{ data.item.price }}
                            </td>
                        </template>
                        <template #freeShipment="data">
                            <td>
                                {{ data.item.campaign && data.item.campaign.free_shipment ? 'Evet' : 'Hayır' }}
                            </td>
                        </template>
                        <template #campaign="data">
                            <td>
                                {{ data.item.campaign ? data.item.campaign.text : '-' }}
                            </td>
                        </template>
                        <template #actions="data">
                            <td>
                                <CButton
                                    block
                                    variant="outline"
                                    color="danger"
                                    @click.stop="removeProduct(data.item.id)"
                                >
                                    Sil
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
                <CCardFooter>
                    <CPagination
                        align="center"
                        :pages="1"
                        :active-page.sync="page"
                    />
                </CCardFooter>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios';


async function getProducts(page, limit) {
    const response = await axios({
        method: 'get',
        url: '/products/paginate',
        headers: {
            'X-Page': page,
            'X-Limit': limit,
        },
    });

    return response.data;
}

export default {
    name: 'ProductList',

    data () {
        return {
            products: [],
            fields: [
                {
                    key: 'name',
                    label: 'Ürün',
                    _classes: 'font-weight-bold'
                },
                {
                    key: 'price',
                    label: 'Fiyat',
                },
                {
                    key: 'campaign',
                    label: 'Kampanya metni',
                },
                {
                    key: 'freeShipment',
                    label: 'Ücretsiz kargo'
                },
                {
                    key: 'actions',
                    label: 'İşlemler',
                }
            ],
            page: 1,
            limit: 50,
            totalCount: 0
        }
    },

    watch: {
        $route: {
            immediate: true,
            async handler (route) {
                let isChanged = false;
                if (route.query && route.query.page) {
                    this.page = Number(route.query.page);
                    isChanged = true;
                }
                if (route.query && route.query.limit) {
                    this.limit = Number(route.query.limit);
                    isChanged = true;
                }

                if (isChanged) {
                    this.loadProducts();
                }
            }
        },
        page: function(newPage) {
            this.$router.push({ query: { page: newPage } });
        }
    },

    methods: {
        redirectProduct(item) {
            this.$router.push({ path: `${item.id}` });
        },

        async loadProducts() {
            const data = await getProducts(this.page, this.limit);
            this.products = data.results;
            this.totalCount = data.count;
        },

        async removeProduct(id) {
            await axios({
                url: `/products/${id}`,
                method: 'delete'
            });

            await this.loadProducts();
        }
    },

    async mounted() {
        this.loadProducts();
    }
}
</script>
